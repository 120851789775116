@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.accountLinkMenu {
  @include font-extended-headline-H5;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  .subscribeSection {
    color: $bone;
    @include sizes-ending-with($screen-tablet-max) {
      display: none;
    }

    .subscribeButton {
      @include font-primary-bold-SB5;
      background-color: rgba($eye-black, 0.2);
      border: 1px solid $bone;
      color: $bone;
      height: 32px;
      line-height: 32px;
      margin-right: $spacing-24;
      text-align: center;
      width: 140px;
    }

    .subscribePrompt {
      @include font-extended-headline-H5;
      color: $bone;
      margin-right: $spacing-24;
      @include sizes-ending-with($new-article-md-desktop-min - 1px) {
        display: none;
      }
    }
  }

  .line {
    @include sizes-ending-with($screen-tablet-max) {
      display: none;
    }
    background-color: $white;
    display: inline-block;
    height: 40px;
    width: 1px;
  }

  .userConversation,
  .userAlerts {
    display: block;
    height: 21px;
    margin-left: $spacing-12;
    width: 21px;
    @include sizes-ending-with($screen-tablet-min) {
      display: none;
    }
  }

  .loginLink {
    @include font-extended-headline-H5;
    color: $white;
    overflow: hidden;
    padding: 15px;
    white-space: nowrap;
    @include sizes-ending-with($screen-tablet-max) {
      padding: 0;
    }
  }

  .searchIcon {
    background: url("/static/icons/icons_search.svg") no-repeat center/20px 20px;
    cursor: pointer;
    height: 25px;
    margin-right: $spacing-12;
    position: relative;
    width: 25px;
    @include sizes-ending-with($screen-mobile-max) {
      margin-right: $spacing-12;
    }

    &.clicked::after {
      border-bottom: 20px solid $white;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      bottom: -32px;
      content: "";
      position: absolute;
      right: 0;
      @include sizes-ending-with($screen-tablet-max) {
        display: none;
      }
    }
  }
}
