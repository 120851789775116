@import "~rivals/styles/colors";
@import "~rivals/styles/variables";
@import "~rivals/styles/typography";

.userAlertListItem {
  background: none;
  border: none;
  border-bottom: 1px solid $bone-50;
  cursor: pointer;
  display: flex;
  padding: $spacing-12 $spacing-4;
  width: 100%;
}

.firstUserAlertListItem {
  border-top: 1px solid $bone-50;
}

.userAlertIconWrapper {
  display: flex;
  flex-shrink: 0;
  margin-top: $spacing-24;
  width: 30px;
}

.userAlertListItemTopRow {
  margin-bottom: $spacing-4;
  text-align: left;
  @include font-primary-bold-SB5;
}

.userAlertListItemMiddleRow {
  align-items: center;
  margin-bottom: $spacing-4;
  text-align: left;
  @include font-primary-body-B5;
}

.bold {
  @include font-primary-bold-SB5;
}

.userAlertListItemBottomRow {
  display: flex;
  text-align: left;
  @include font-primary-body-B5;
}

.userAlertListItemBottomRowSeparator {
  margin-left: $spacing-4;
  margin-right: $spacing-4;
}
