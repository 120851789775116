@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.bellIcon {
  cursor: pointer;
}

.bellIconUnreadSymbol {
  background-color: red;
  border-radius: 50%;
  height: 8px;
  left: $spacing-4;
  position: absolute;
  top: -$spacing-2;
  width: 8px;
}

.bellIconWrapper {
  display: flex;
  position: relative;
}

.userAlerts {
  margin-left: $spacing-8;
}
