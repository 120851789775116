@import "~rivals/styles/breakpoints";
@import "~rivals/styles/variables";

.container {
  align-items: center;
  background-color: $bone;
  display: flex;
  flex-direction: column;
}

.grid {
  column-gap: $spacing-36;
  display: grid;
  grid-template-areas: "leftRail body rightRail";
  grid-template-columns: $rail-width $body-width $rail-width;
  margin-top: $spacing-36;
  @include sizes-ending-with($screen-lg-mobile-max) {
    column-gap: $spacing-12;
    grid-template-areas:
      "body"
      "leftRail";
    grid-template-columns: 1fr;
    margin: $spacing-36 $spacing-12 0 $spacing-12;
    row-gap: $spacing-36;
  }
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    column-gap: $spacing-12;
    grid-template-areas:
      "body"
      "leftRail";
    grid-template-columns: $body-width;
  }
  @include sizes-between(
    $screen-sm-desktop-min,
    $container-width-desktop-XL - 1
  ) {
    column-gap: $spacing-12;
    grid-template-areas:
      "leftRail body"
      "rightRail body"
      ". body";
    grid-template-columns: $rail-width $body-width;
  }
}

.body {
  background-color: lightblue;
  grid-area: body;
  @include sizes-ending-with($screen-lg-mobile-max) {
    overflow: scroll;
    width: 100%;
  }
}

// TODO: Remove color and height when implementing https://griosf.atlassian.net/browse/RVLS-10704

.leftRail {
  background-color: lightgreen;
  grid-area: leftRail;
  height: 100%;
  min-height: 50vh;
}

// TODO: Remove color and height when implementing https://griosf.atlassian.net/browse/RVLS-10705

.rightRail {
  background-color: lightcoral;
  grid-area: rightRail;
  height: 100%;
  min-height: 50vh;
  @include sizes-ending-with($screen-lg-mobile-max) {
    display: none;
  }
}
