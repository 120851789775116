@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.userAlertIconAvatarIcon {
  align-items: center;
  background: $gray85;
  border-radius: 50%;
  color: $eye-black;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  @include font-primary-medium-S5;
  line-height: unset;
  text-transform: uppercase;
  width: 24px;
}

.userAlertIconAvatarImage {
  border-radius: 50%;
  height: 24px;
  width: 24px;
}
