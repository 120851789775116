@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.userAlertListDropdown {
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin-right: -$spacing-10;
  padding: $spacing-12;
  width: 300px;
  @include sizes-ending-with($screen-lg-mobile-max) {
    left: $spacing-12;
    position: relative;
    width: 100%;
  }

  // The below nesting is to prevent conflicts with default Antd styles, without having to use !important

  .userAlertListWrapper {
    box-shadow: none;
    margin: 0;
    padding: 0;

    :global(.ant-dropdown-menu-item) {
      margin: 0;
      padding: 0;

      &:hover {
        background: none;
      }
    }
  }
}

.userAlertListDropdownHeader {
  @include font-primary-bold-SB5;
  padding-bottom: $spacing-8;
}

.infiniteScrollContainer {
  height: calc(100vh - 175px);
  max-height: 700px;
  overflow-y: auto;
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: calc(100vh - 300px);
  }
}

.infiniteScrollLoadingGraphic {
  padding: $spacing-10;
}

.userAlertListDropdownFooter {
  display: flex;
  min-width: 200px;
  padding-top: $spacing-8;
  @include font-primary-body-B5;
}

.userAlertListDropdownFooterSeparator {
  margin-left: $spacing-4;
  margin-right: $spacing-4;
}

.userAlertListDropdownFooterPreferences {
  color: unset;
  text-decoration: none;

  &:hover {
    color: unset;
  }
}

.userAlertListDropdownMarkReadButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
